/* eslint-disable camelcase */
import React from 'react';
import { change, reset, formValueSelector, untouch, getFormValues } from 'redux-form';
import { error, success, warning } from 'react-notification-system-redux';
import { useSelector, useDispatch } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { useHistory } from 'react-router-dom';
import { filter, find, forEach, get, includes, isEmpty, isNil, keys, map, toString, pick, head } from 'lodash';

import {
  removeSpecialSymbols,
  convertDocument,
  resetFields,
  toDate,
  verifyDifferentValues,
  evaluate_permissions,
} from '../utils/functions';
import {
  CurrentRegistrationData,
  UserOptionsData,
  StudentStepFormData,
  ConnectionOption,
  Paths,
  NestedEmergencyContactAttributes,
  NestedRegistrationConnectionAttributes,
  NestedUserAlergyAttributes,
  NestedUserDeficiencyAttributes,
  AccountsAttributes,
  Role,
  UserAttributes,
  EmergencyContactAttributes,
  NestedAccountAttributesStudentForm,
  RegistrationConnectionAttributes,
  NestedRegistrationAttributesStudentForm,
  RegistrationStatusEnum,
  NestedUserRaceAttributes,
} from '../utils/constants';
import { AccountJson, CREATE_ACCOUNT, UPDATE_ACCOUNT } from '../store/accounts';
import { DELETE_USER_AVATAR, AddressJson, UserJson, VERIFY_USER_HIERARCHY, UPDATE_USER, CREATE_USER } from '../store/users';
import {
  FETCH_REGISTRATIONS,
  FETCH_REGISTRATION,
  EmergencyContactsJson,
} from '../store/registrations';
import { RootState } from '../store/configureStore';
import StudentStepForm from '../components/form/StudentStepForm';
import { UserAlergyJson } from '../store/alergies';
import { UserDeficiencyJson } from '../store/deficiencies';
import Loading from '../components/loading/Loading';
import { RegistrationConnectionJson } from '../store/registration_connections';
import { UserRaceJson } from '../store/races';

function objectToFormData(obj: object, rootName:string, ignoreList:string[], initial_form_data = new FormData()) {
  const formData = initial_form_data

  function ignore(root:string){
    return Array.isArray(ignoreList)
        && ignoreList.some(function(x) { return x === root; });
}
  function appendFormData(data: object, root: string) {
      if (!ignore(root)) {
          root = root || '';
          if (data instanceof File) {
              formData.append(root, data);
          } else if (Array.isArray(data)) {
              for (let i = 0; i < data.length; i++) {
                  appendFormData(data[i], root + '[' + i + ']');
              }
          } else if (typeof data === 'object' && data) {
              for (const key in data) {
                  // eslint-disable-next-line no-prototype-builtins
                  if (data.hasOwnProperty(key)) {
                      if (root === '') {
                          appendFormData(data[key as keyof typeof data], `[${key}]`);
                      } else {
                          appendFormData(data[key as keyof typeof data], root + `[${key}]`);
                      }
                  }
              }
          } else {
              if (data !== null && typeof data !== 'undefined') {
                  formData.append(root, data);
              }
          }
      }
  }


  appendFormData(obj, rootName);

  return formData;
}

const emergencyContactsUpdater = (
  currentEmergencyContacts: NestedEmergencyContactAttributes[],
  initialEmergencyContacts: NestedEmergencyContactAttributes[],
) => {
  const sorted: NestedEmergencyContactAttributes[] = [];
  forEach(currentEmergencyContacts, (emergency_contact) => {
    const related = find(initialEmergencyContacts, (initial) => initial.id === emergency_contact.id);
    if (isNil(related)) {
      sorted.push(emergency_contact);
      return;
    }
    const omit = ['id', '_destroy', 'registration_id'];
    const result = verifyDifferentValues(emergency_contact, related, omit) as NestedEmergencyContactAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedEmergencyContactAttributes[];
};

const registrationConnectionsUpdater = (
  currentRegistrationConnections: NestedRegistrationConnectionAttributes[],
  initialRegistrationConnections: NestedRegistrationConnectionAttributes[],
) => {
  const sorted: NestedRegistrationConnectionAttributes[] = [];
  forEach(currentRegistrationConnections, (registration_connection) => {
    const related = find(initialRegistrationConnections, (initial) => initial.id === registration_connection.id);
    if (isNil(related)) {
      sorted.push(registration_connection);
      return;
    }
    const omit = ['id', '_destroy', 'registration_id'];
    const result = verifyDifferentValues(
      registration_connection,
      related,
      omit,
    ) as NestedRegistrationConnectionAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedRegistrationConnectionAttributes[];
};


const registrationsUpdater = (
  currentRegistrationAttributes: NestedRegistrationAttributesStudentForm[],
  initialRegistrationAttributes: NestedRegistrationAttributesStudentForm[],
) => {
  const sorted: NestedRegistrationAttributesStudentForm[] = [];
  currentRegistrationAttributes.forEach((registration_attributes) => {
    const related = find(initialRegistrationAttributes, (initial) => initial.id === registration_attributes.id);
    if (isNil(related)) {
      sorted.push(registration_attributes);
      return;
    }
    const result = verifyDifferentValues(registration_attributes, related, [
      'id',
      '_destroy',
      'emergency_contacts_attributes',
      'registration_connections_attributes',
      'student_code',
      'account_id',
    ]) as NestedRegistrationAttributesStudentForm;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentEmergencyContacts = registration_attributes.emergency_contacts_attributes;
      const initialEmergencyContacts = filter(related?.emergency_contacts_attributes, (rep) => rep.id);
      const emergency_contacts_attributes = emergencyContactsUpdater(
        currentEmergencyContacts as NestedEmergencyContactAttributes[],
        initialEmergencyContacts as NestedEmergencyContactAttributes[],
      );

      const currentRegistrationConnections = registration_attributes.registration_connections_attributes
      const initialRegistrationConnections =  filter(related?.registration_connections_attributes, (rep) => rep.id);
      const registration_connections_attributes = registrationConnectionsUpdater(
        currentRegistrationConnections as NestedRegistrationConnectionAttributes[],
        initialRegistrationConnections as NestedRegistrationConnectionAttributes[],
      );
      const formattedteacher = {
        ...result,
        emergency_contacts_attributes,
        registration_connections_attributes
      };
      sorted.push(formattedteacher);
    }
  });
  return sorted as NestedRegistrationAttributesStudentForm[];
};


const accountsUpdater = (
  currentAccountAttributes: NestedAccountAttributesStudentForm[],
  initialAccountAttributes: NestedAccountAttributesStudentForm[],
) => {
  const sorted: NestedAccountAttributesStudentForm[] = [];
  currentAccountAttributes.forEach((accounts_attributes) => {
    const related = find(initialAccountAttributes, (initial) => initial.id === accounts_attributes.id);
    if (isNil(related)) {
      sorted.push(accounts_attributes);
      return;
    }
    const result = verifyDifferentValues(accounts_attributes, related, [
      'id',
      '_destroy',
      'registrations_attributes',
      'company_id',
      'user_id',
    ]) as NestedAccountAttributesStudentForm;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      const currentRegistrationAttributes = accounts_attributes.registrations_attributes;
      const initialRegistrationAttributes = filter(related?.registrations_attributes, (rep) => rep.id);
      const registrations_attributes = registrationsUpdater(
        currentRegistrationAttributes as NestedRegistrationAttributesStudentForm[],
        initialRegistrationAttributes as NestedRegistrationAttributesStudentForm[],
      );
      const formattedteacher = {
        ...result,
        registrations_attributes
      };
      sorted.push(formattedteacher);
    }
  });
  return sorted as NestedAccountAttributesStudentForm[];
};


const userAlergiesUpdater = (
  currentUserAlergies: NestedUserAlergyAttributes[],
  initialUserAlergies: NestedUserAlergyAttributes[],
) => {
  const sorted: NestedUserAlergyAttributes[] = [];
  forEach(currentUserAlergies, (user_alergy) => {
    const related = find(initialUserAlergies, (initial) => initial.id === user_alergy.id);
    if (isNil(related)) {
      sorted.push(user_alergy);
      return;
    }
    const omit = ['id', '_destroy', 'user_id'];
    const result = verifyDifferentValues(user_alergy, related, omit) as NestedUserAlergyAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedUserAlergyAttributes[];
};

const userRacesUpdater = (
  currentUserAlergies: NestedUserRaceAttributes[],
  initialUserAlergies: NestedUserRaceAttributes[],
) => {
  const sorted: NestedUserRaceAttributes[] = [];
  forEach(currentUserAlergies, (user_alergy) => {
    const related = find(initialUserAlergies, (initial) => initial.id === user_alergy.id);
    if (isNil(related)) {
      sorted.push(user_alergy);
      return;
    }
    const omit = ['id', '_destroy', 'user_id'];
    const result = verifyDifferentValues(user_alergy, related, omit) as NestedUserRaceAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedUserRaceAttributes[];
};

const userDeficienciesUpdater = (
  currentUserDeficiencies: NestedUserDeficiencyAttributes[],
  initialUserDeficiencies: NestedUserDeficiencyAttributes[],
) => {
  const sorted: NestedUserDeficiencyAttributes[] = [];
  forEach(currentUserDeficiencies, (user_deficiencies) => {
    const related = find(initialUserDeficiencies, (initial) => initial.id === user_deficiencies.id);
    if (isNil(related)) {
      sorted.push(user_deficiencies);
      return;
    }
    const omit = ['id', '_destroy', 'user_id'];
    const result = verifyDifferentValues(user_deficiencies, related, omit) as NestedUserDeficiencyAttributes;
    if (keys(result).filter((key) => key !== 'id').length > 0) {
      sorted.push(result);
    }
  });
  return sorted as NestedUserDeficiencyAttributes[];
};
const defaultInitialValues = (company_id?: number) => ({
  company_id: company_id,
  nationality_country: {
    phonecode: '55',
    flag: '🇧🇷',
    label: 'Brazil',
    value: 'BR',
  },
  accounts_attributes: [
    {
      company_id,
      active: true,
      registrations_attributes: [
        {
          status: RegistrationStatusEnum.DRAFT,
          emergency_contacts_attributes: [],
          registration_connections_attributes: []
        },
      ],
    },
  ],
});
const form_name = 'registration-studentstep';

const StudentStepContainer = (props: any) => {
  const {
    setLoading,
    setStep,
    currentRegistration,
    setCurrentRegistration
  }: {
    setLoading: (value: boolean) => void;
    setStep: React.Dispatch<React.SetStateAction<number>>;
    setCurrentRegistration: React.Dispatch<React.SetStateAction<CurrentRegistrationData | undefined>>;
    currentRegistration: CurrentRegistrationData;
    company: string;
  } = props;
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const formValues = formValueSelector(form_name);
  const {
    auth: { company: companyId, profile },
  } = state;
  const values = getFormValues(form_name)(state) as StudentStepFormData;
  const companyIdValue = formValues(state, 'company_id') as string;
  const is_above_school_admin = evaluate_permissions.is_above_school_admin(profile.role as Role);
  const company_id_to_use = currentRegistration?.id
  ? currentRegistration.company_id
  : is_above_school_admin
  ? companyIdValue
  : companyId;
  const [connectionOptions, setConnectionOptions] = React.useState<ConnectionOption[]>([]);
  const [initialValues, setInitialValues] = React.useState<Partial<StudentStepFormData>>(defaultInitialValues(company_id_to_use));
  const [preLoadingOptions, setPreLoadingOptions] = React.useState<string[]>([]);
  const [allowUserUpdate, setAllowUserUpdate] = React.useState(true)
  const result = initialValues && values && verifyDifferentValues(values, initialValues);
  const thereIsDifferentvalues = keys(result).length > 0;

  const deleteUserAvatarMethod = React.useCallback(async (id: string) => {
    try {
      await dispatch(DELETE_USER_AVATAR.request({ id }));
    } catch (err) {
      dispatch(
        error({
          message: 'Erro na exclusão da imagem do usuário',
          autoDismiss: 3,
        }),
      );
    }
  }, []);
  const fetchRegistrationsMethod = React.useCallback(async (params: object) => {
    try {
      const registrations = await dispatch(
        FETCH_REGISTRATIONS.request({
          params: {
            filters: {
              ...params,
            },
          },
        }),
      );
      return registrations;
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao carregar informações de matrículas',
        }),
      );
    }
  }, []);

  const checkExistingRegistrationsForUser = React.useCallback(
    async (id: string, document: string) => {
      try {
        let allowed = false;
        const param = id ? 'q[account_user_id_eq]' : 'q[account_user_document_number_eq]';
        const registrations = await fetchRegistrationsMethod({
          'q[account_company_id_eq]': company_id_to_use,
          [param]: id || document,
        });
        if (registrations) {
          const {
            data: { data },
          } = registrations;
          allowed = data.length === 0;
          if (!allowed) {
            resetFields(form_name, ['document_number'], dispatch, change, untouch);
            dispatch(
              warning({
                message: 'Aluno com este documento já tem matrícula na escola',
              }),
            );
          }
        }
        return allowed;
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao checar matrículas de usuário',
          }),
        );
        return false;
      }
    },
    [company_id_to_use],
  );
  const insertUserData = React.useCallback(
    async (user: UserOptionsData, can_update_user, company_id = company_id_to_use) => {
      const initial_country_options = map(
        Country.getAllCountries(),
        ({ latitude, longitude, timezones, name, isoCode, ...rest }) => ({
          ...rest,
          label: name,
          value: isoCode,
        }),
      );
      try {
        setLoading(true);
        const {
          additional_data,
          address_attributes,
          user_alergies_attributes,
          user_deficiencies_attributes,
          birthdate,
          birthplace,
          gender,
          document_number,
          name,
          email,
          phone,
          nationality,
          id,
          account,
          profiles,
          avatar_url,
          avatar_attachment_id,
          user_races_attributes
        } = user;
        let avatarData = {};
        if (avatar_url) {
          avatarData = {
            avatar: {
              url: avatar_url,
              id: avatar_attachment_id,
            },
          };
        }
        const date = birthdate ? toDate(birthdate) : null;
        const nationality_state = birthplace?.substring(birthplace.lastIndexOf('-') + 1);
        const nationality_city = birthplace?.substring(0, birthplace.lastIndexOf('-'));
        const countryInfo = initial_country_options.find((item) => item.label === nationality);

        const stateInfo = find(
          map(State.getStatesOfCountry(countryInfo?.value as string), ({ name, isoCode, countryCode }) => ({
            label: name,
            value: name,
            isoCode,
            countryCode,
          })),
          (item) => item.isoCode === nationality_state,
        );
        const cityInfo =
          find(
            map(City.getCitiesOfState(stateInfo?.countryCode as string, stateInfo?.isoCode as string), ({ name }) => ({
              label: name,
              value: name,
            })),
            (item) => item.value === nationality_city,
          ) || nationality_city;
        let emergency_contacts_attributes = [] as NestedEmergencyContactAttributes[];
        let registration_connections_attributes = [] as NestedRegistrationConnectionAttributes[];
        if (currentRegistration) {
          registration_connections_attributes = currentRegistration.registration_connections_attributes || [];
          emergency_contacts_attributes = currentRegistration.emergency_contacts_attributes || [];
        }
        const formattedData = {
          name,
          additional_data,
          address_attributes,
          user_alergies_attributes,
          user_deficiencies_attributes,
          email,
          gender,
          phone: includes(phone, 'undefined') ? '' : phone,
          birthdate: date,
          nationality_country: countryInfo,
          nationality_state: stateInfo,
          nationality_city: cityInfo,
          registration_connections_attributes,
          emergency_contacts_attributes,
          user_races_attributes,
          id,
          account: account,
          student_code: account?.student_code || currentRegistration?.code,
          profiles: profiles,
          document_number: convertDocument(document_number),
          company_id: company_id,
          ...avatarData,
          accounts_attributes: user.accounts_attributes || [
            {
              ...(user?.account?.id ? {id: user.account?.id} : {}),
              company_id: company_id,
              active: true,
              user_id: id,
              registrations_attributes: [
                {
                  status: RegistrationStatusEnum.DRAFT,
                  emergency_contacts_attributes: [],
                  registration_connections_attributes: []
                }
              ]
            }
          ]
        } as StudentStepFormData;
        setAllowUserUpdate(can_update_user)
        setInitialValues(formattedData);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        dispatch(reset(form_name));
        dispatch(
          error({
            message: 'Erro no carregamento de dados do usuário, favor tentar novamente',
          }),
        );
      }
    },
    [company_id_to_use, currentRegistration],
  );
  const verifyCurrentAndSelectedUser = React.useCallback(
    async (id: string) => {
      try {
        const result = await dispatch(
          VERIFY_USER_HIERARCHY.request({
            id
          }),
        );
        const { data } = result
        return data
      } catch (err) {
        dispatch(
          error({
            message: 'Erro ao checar hierarquida do usuário selecionado',
          }),
        );
        return false;
      }
    },
    [],
  );

  const fetchStudentStepData = React.useCallback(async () => {
    setLoading(true);
    setPreLoadingOptions((item) => item.concat('initialValues'));
    const registration = await dispatch(
      FETCH_REGISTRATION.request({
        id: currentRegistration.id,
        params: {
          filters: {
            include: [
              'account.user.address',
              'account.user.user_alergies',
              'account.user.user_deficiencies',
              'account.user.user_races',
              'account.user.accounts',
              'account.company',
              'emergency_contacts',
              'registration_connections'
            ].join(','),
          },
        },
      }),
    );

    const {
      data: {
        data: { attributes, id },
        included,
      },
    } = registration;
    const includedAccounts = map(filter(included, (incl) => incl.type === 'accounts') as AccountJson[], (item) => ({
      id: item.id,
      ...item.attributes,
    }));

    const includedEmergencyContacts = filter(included, (item) => {
      return item.type === 'emergency_contacts';
    }) as EmergencyContactsJson[];

    const emergency_contacts_attributes = map(includedEmergencyContacts, (item) => ({
      id: item.id,
      ...item.attributes,
    })) as EmergencyContactAttributes[]
    const registration_connections_attributes = map(filter(included, incl => incl.type === 'registration_connections') as RegistrationConnectionJson[], item => ({ id: item.id, ...item.attributes })) as RegistrationConnectionAttributes[]
    const includedUsers = map(filter(included, (incl) => incl.type === 'users') as UserJson[], (item) => ({
      id: item.id,
      ...item.attributes,
    }));

    const registration_account = includedAccounts.find(
      (item) => ~~item.id === attributes.account_id,
    ) as AccountsAttributes;
    const registration_user = includedUsers.find(
      (item) => ~~item.id === registration_account.user_id,
    ) as UserAttributes;
    const includedAddress = included.find((item) => item.type === 'addresses') as AddressJson;
    let address_attributes;
    if (includedAddress) {
      const {
        attributes: { city_id, state_id, country_id, ...rest },
      } = includedAddress;
      address_attributes = {
        id: includedAddress.id,
        city_id: toString(city_id),
        state_id: toString(state_id),
        country_id: toString(country_id),
        ...rest,
      };
    }
    const includedAlergies = included.filter((item) => item.type === 'user_alergies') as UserAlergyJson[];
    const user_alergies_attributes = includedAlergies.map((item) => {
      return {
        id: item.id,
        ...item.attributes,
      };
    });
    const includedDeficiencies = included.filter((item) => item.type === 'user_deficiencies') as UserDeficiencyJson[];
    const user_deficiencies_attributes = includedDeficiencies.map((item) => {
      return {
        id: item.id,
        ...item.attributes,
      };
    });
    const user_races_attributes = map(filter(
      included,
      (item) => item.type === 'user_races',
    ) as UserRaceJson[], item => ({id: item.id, ...item.attributes}))
    const accounts_attributes = [
      {
        id: registration_account.id,
        registrations_attributes: [
          {
            id,
            ...pick(attributes, ['account_id']),
            emergency_contacts_attributes,
            registration_connections_attributes
          },
        ],
      },
    ] as NestedAccountAttributesStudentForm[];
    const can_update_user = await verifyCurrentAndSelectedUser(registration_user.id)

    await insertUserData({
      ...registration_user,
      address_attributes,
      user_alergies_attributes,
      user_deficiencies_attributes,
      user_races_attributes,
      accounts_attributes,
    }, can_update_user);
    setLoading(false);
    setPreLoadingOptions((cur) => cur.filter((item) => item !== 'initialValues'));
  }, [currentRegistration, company_id_to_use]);

  const handleSelectDocumentValue = React.useCallback(
    async (user: UserOptionsData) => {
      try {
          const canInsertUser = await checkExistingRegistrationsForUser(user.id, '');
          if (canInsertUser) {
            setPreLoadingOptions((item) => item.concat('initialValues'));
            dispatch(reset(form_name))
            const can_update_user = await verifyCurrentAndSelectedUser(user.id)
            await insertUserData(user, can_update_user, company_id_to_use);
            setPreLoadingOptions((cur) => cur.filter((item) => item !== 'initialValues'));
          }
      } catch (err) {
        setPreLoadingOptions((cur) => cur.filter((item) => item !== 'initialValues'));
        dispatch(
          error({
            message: 'Erro após selecionar usuário por CPF',
            autoDismiss: 3,
          }),
        );
      }
    },
    [company_id_to_use],
  );

  const formatSubmitUserData = React.useCallback(
    async (data: StudentStepFormData) => {
      try {
        const {
          user_alergies_attributes: initialUserAlergiesAttributes = [],
          user_deficiencies_attributes: initialUserDeficienciesAttributes = [],
          user_races_attributes: initialUserRacesAttributes = []
        } = initialValues;
        const {
          address_attributes,
          name,
          email,
          gender,
          birthdate,
          phone,
          document_number,
          nationality_country,
          nationality_state,
          nationality_city,
          avatar,
          additional_data,
          id
        } = data;
        let {
          user_alergies_attributes,
          user_deficiencies_attributes,
          accounts_attributes,
          user_races_attributes
        } = data
        const is_updating_user = !isEmpty(id)
        const is_updating_account = !isEmpty(get(data, 'accounts_attributes[0].id'))
        if(is_updating_user){
          user_alergies_attributes = userAlergiesUpdater(user_alergies_attributes, initialUserAlergiesAttributes)
          user_deficiencies_attributes = userDeficienciesUpdater(
            user_deficiencies_attributes,
            initialUserDeficienciesAttributes,
          )
          user_races_attributes = userRacesUpdater(user_races_attributes, initialUserRacesAttributes)
        }
        if(is_updating_account) {
          accounts_attributes = accountsUpdater(accounts_attributes, initialValues.accounts_attributes || [])
        }
        const nationalityCity = `${
          nationality_city && nationality_city['label'] ? nationality_city['label'] : nationality_city
        }`;
        const nationalityState = `${
          nationality_state && nationality_state['isoCode'] ? nationality_state['isoCode'] : ''
        }`;
        const birthplace = filter([nationalityCity, nationalityState], (item) => !isEmpty(item)).join('-') || '';
        const formattedData = {
          name: name.trim().replace('  ', ' '),
          email,
          gender,
          birthdate: new Date(birthdate).toISOString(),
          phone,
          document_type:
            document !== undefined && removeSpecialSymbols(document_number)?.length <= 11 ? 'cpf' : 'cnpj',
          document_number: removeSpecialSymbols(document_number),
          nationality: nationality_country && (nationality_country['label'] as string),
          birthplace,
          additional_data,
          address_attributes,
          user_races_attributes,
          user_alergies_attributes,
          user_deficiencies_attributes,
          accounts_attributes
        };
        let formData = new FormData();
        if(allowUserUpdate){
          formData.append('data[type]', 'users');
          id && formData.append('data[id]', id)
          formData = objectToFormData(formattedData, 'data[attributes]', [], formData)
        }
        
        if (
          allowUserUpdate &&
          initialValues &&
          ((initialValues?.avatar?.id && !avatar?.id && avatar) || (!initialValues?.avatar && avatar))
        ) {
          formData.append('data[attributes][avatar]', avatar['file'] as Blob);
        }
        return { formData, formatted_data: formattedData };
      } catch (err) {
        console.error(err);
        dispatch(
          error({
            message: 'Erro ao formatar dados do formulário',
          }),
        );
      }
    },
    [initialValues, company_id_to_use],
  );

  const createRegistrationMethod = async (user_id: number) => {
    try {
      const registrations = await dispatch(
        FETCH_REGISTRATIONS.request({
          params: {
            filters: {
              'q[student_user_id_eq]': user_id.toString(),
              'q[company_id_eq]': company_id_to_use,
              'include': ['emergency_contacts', 'registration_connections'].join(',')
            }
          }
        })
      )
      const { data: { data, included } } = registrations
      const registration_attributes = data[0]
      const emergency_contacts_attributes = filter(included, item => item.type === 'emergency_contacts' && item.attributes.registration_id === ~~registration_attributes.id).map(item => ({ id: item.id, ...item.attributes })) as EmergencyContactAttributes[]
      const registration_connections_attributes = filter(included, item => item.type === 'registration_connections' && item.attributes.registration_id === ~~registration_attributes.id).map(item => ({ id: item.id, ...item.attributes })) as RegistrationConnectionAttributes[]

      const registration = {
        id: registration_attributes.id,
        ...registration_attributes.attributes,
        emergency_contacts_attributes,
        registration_connections_attributes
      }
      setCurrentRegistration(registration)
      dispatch(
        success({
          message: `Matrícula criada com sucesso!`,
          autoDismiss: 3,
        }),
      );
    } catch (err) {
      dispatch(
        error({
          message: 'Erro ao criar matrícula.',
        }),
      );

    }
  }

  const saveAccountMethod = React.useCallback(
    async (data: Partial<StudentStepFormData>) => {
      const account_data = head(data.accounts_attributes) as NestedAccountAttributesStudentForm
      const {id, ...rest} = account_data

      const isUpdatingAccount = id
      const user_id = account_data.user_id as number
      try {
        if (isUpdatingAccount) {
          await dispatch(
            UPDATE_ACCOUNT.request({
              id,
              data: {...rest},
            }),
          );
        } else {
          await dispatch(
            CREATE_ACCOUNT.request({
              data: {...account_data},
            }),
          );
        }
        if(!currentRegistration?.id && user_id){
          createRegistrationMethod(user_id)
        }
        dispatch(
          success({
            message: `Conta do Estudante ${isUpdatingAccount ? 'atualizada' : 'criada'} com sucesso!`,
            autoDismiss: 3,
          }),
        );
      } catch (er) {
        dispatch(
          error({
            message: 'Erro ao salvar conta do estudante.',
          }),
        );
      }
    },
    [],
  );


  const saveUserMethod = React.useCallback(
    async (form_data: FormData, formatted_data: Partial<StudentStepFormData>) => {
      try {
        const isUpdating = initialValues.id
        let user_id = initialValues.id
        if (isUpdating) {
          await dispatch(
            UPDATE_USER.request({
              id: initialValues.id as string,
              data: form_data,
            }),
          );
          if (initialValues?.avatar?.id && !formatted_data.avatar) {
            await deleteUserAvatarMethod(initialValues.id as string);
          }
        } else {
        const response = await dispatch(
            CREATE_USER.request({
              data: form_data
            }),
          );
        const { data: { data: { id } } } = response
        user_id = id
        }
        if(!currentRegistration?.id && user_id){
          createRegistrationMethod(~~user_id)
        }
        dispatch(
          success({
            message: `Usuário do estudante ${isUpdating ? 'atualizado' : 'criado'} com sucesso!`,
            autoDismiss: 3,
          }),
        );
        dispatch(reset(form_name));
      } catch (e) {
        dispatch(
          error({
            title: 'Erro',
            message: 'Erro ao salvar usuário do estudante, favor entrar em contato com suporte.',
            autoDismiss: 3,
          }),
        );
      }
    },
    [initialValues, company_id_to_use],
  );


  const onSubmitStudentStep = async (payload: { data: StudentStepFormData; advance: boolean }) => {
    const { data, advance = true } = payload;
    setLoading(true);
    const handleAdvance = () => {
      if (advance) {
        setStep((step) => step + 1);
      } else {
        history.push(Paths.SECRETARY);
      }
    };
    try {
      const { formData, formatted_data } = await formatSubmitUserData(data) as { formData: FormData, formatted_data: Partial<StudentStepFormData> }
      if (allowUserUpdate) {
        await saveUserMethod(formData, formatted_data)
      } else {
        await saveAccountMethod(formatted_data)
      }
      handleAdvance()
      setLoading(false);
    } catch (e) {
      console.error(e);
      dispatch(
        error({
          message: 'Erro na manutenção da matricula',
          autoDismiss: 3,
        }),
      );
      setLoading(false);
    }
  };

  const initForm = async () => {
    if (currentRegistration?.id) {
      fetchStudentStepData();
    } else {
      defaultInitialValues(company_id_to_use)
    }
  };

  React.useEffect(() => {
    initForm();
  }, []);


  if (preLoadingOptions.length) {
    return <Loading />;
  }
  return (
    <StudentStepForm
      allowUserUpdate={allowUserUpdate}
      initialValues={initialValues}
      connectionOptions={connectionOptions}
      setConnectionOptions={setConnectionOptions}
      onSubmit={onSubmitStudentStep}
      currentRegistration={currentRegistration}
      setInitialValues={setInitialValues}
      defaultInitialValues={defaultInitialValues}
      fetchRegistrationsMethod={fetchRegistrationsMethod}
      handleSelectUser={handleSelectDocumentValue}
      thereIsDifferentValues={thereIsDifferentvalues}
      checkExistingRegistrationsForUser={checkExistingRegistrationsForUser}
    />
  );
};

export default StudentStepContainer;
